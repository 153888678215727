<template>
  <v-main class="ma-0 pa-0">
    <div>
      <v-layout wrap>
        <v-flex xs12 v-for="(item, i) in data" :key="i">
          <v-carousel
            height="auto"
            :hide-delimiter-background="true"
            :hide-delimiters="true" 
            @change="change(i,$event)"
            :style="isMobile ? 'min-height:265px': 'min-height:700px'"
          >
            <v-carousel-item v-for="(content, index) in item.url_media" :key="index" @click="goTo(item)">
              <v-row class="fill-height" align="center" justify="center">
                <!-- <div :class="isMobile ? 'text-center-m': 'text-center'" :style="item.font_color ? { color: item.font_color } : { color: 'white'}" > -->
                <div class="slider-content" :style="item.font_color ? { color: item.font_color } : { color: 'white'}" >
                  <h1 class="title"> {{ item.category_name_slider }} </h1>
                  <p class="subtitle" v-html="item.description">
                  </p>
                  <v-btn tile v-if="item.is_button == 'YES'" class="ma-2" outlined :color="item.font_color ? item.font_color : 'Black'" @click="actions(item.category_name, true)">
                    Shop Now
                  </v-btn>
                </div>
                <img v-if="content.type === 'image'" v-lazyload v-bind:src="content.url" width="100%" alt="item">
                <div v-else class="slider-video">
                  <video
                    autoplay
                    muted
                    playsinline
                    loop
                    width="100%"
                    :height="isMobile ? '265px' :'700px'"
                    :id="'video-'+ i + '-' + index" 
                    :ref="'video-'+ i + '-' + index"
                  >
                    <source :src="content.url">
                  </video>
                </div>
              </v-row>
            </v-carousel-item>
          </v-carousel>
          <HomeSlider v-if="item.slide_option && item.slide_option === 'HORIZONTAL'" :shop="item.item" :item="item" :parentindex="i"/>
          <HomeProduct v-else :shop="item.item" :item="item" :parentindex="i"/>
          <div v-if="!item.item || item.item.length === 0" class="mb-10"></div>
        </v-flex>
      </v-layout>
    </div>
    <Category v-if="categories && categories.length > 0" :header="header" :category="categories" />
  </v-main>
</template>

<script>

import { mapState } from 'vuex'
import axios from '@/axios'
// import HomeProduct from '@/components/HomeProduct'
import HomeProduct from '@/components/Shop/HomeProductV2'
import Category from '@/components/Category'
import HomeSlider from '@/components/HomeSlider/HomeSlider'

export default {
  name: 'PagesId',
  components: { Category, HomeSlider, HomeProduct },
  computed: {
    ...mapState([
      'isMobile',
      'yIndexHome'
    ]),
  },
  data() {
    return {
      data: [],
      images: [],
      categories: [],
      header: '',
      click: 0,
      brand: process.env.VUE_APP_BRAND,
      playindicator: [],
    }
  },
  methods: {
    goTo (item) {
      this.click = this.click + 1
      if (item.is_button === 'NO' && this.click === 1) {
        this.$store.commit('setProductType', 'category')
        // this.toShop(item.category_name)
         this.toShopV2(item)
      }
    },
    scrollIntoView () {
      let id = 'row-' + this.yIndexHome
      setTimeout(()=> {
        var element = document.getElementById(id);
        element.scrollIntoView({
          behaviour: 'auto',
          block: 'center',
          inline: 'center'
        });
        // element.scrollIntoView({behavior: "smooth"});
        this.$store.commit('setYIndexHome', null)
      }) 
    },
    createPlayerIndicator (data) {
      let result = []
      if (this.data) {
        data.forEach((item, index) => {
          if (item.url_media.length > 0) {
            item.url_media.forEach((content,i) => {
              if (content.type === 'video') {
                result.push({
                  id: 'video-' + index + '-' + i,
                  play: false
                })
              }
            })
          }
        })
        this.playindicator = result
      }
    },
    play (id, indicatorIndex) {
      setTimeout(() => {
        let video = document.getElementById(id)
        if (video) {
          if (this.playindicator[indicatorIndex].play) {
            video.pause()
            this.playindicator[indicatorIndex].play = false
          } else {
            video.play()
            this.playindicator[indicatorIndex].play = true
          }
        }
      },100) 
    },
    playVideo (i, index) {
      let id = 'video-' + i + '-' + index;
      let indicatorIndex = this.playindicator.findIndex(item => item.id === id)
      if (this.playindicator.find(item => item.id === id)) {
        this.play(id, indicatorIndex)
      } 
      else {
        this.setPauseVideo()
      }
    },
    setPauseVideo () {
      this.playindicator.forEach((item,index) => {
        if (item.play === true) {
          this.play(item.id, index)
        }
      })
    },
    change (i, index) {
      this.playVideo(i, index)
    },
    setSliderImage (data) {
      let images = []
      data.forEach((item, index) => {
        if (index === 0) {
          item.url_image.forEach(img => {
            images.push([img])
          })
        } else {
          item.url_image.forEach((img,i) => {
            images[i].push(img)
          })
        }
      })
      this.images = images
    },
    getSlider () {
      this.$store.commit('setIsLoading', true)
      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        is_child: 'YES'
      }
      axios.get('/api/v1/pages/home')
        .then(response => {
          this.data = response.data.data.slider
          this.categories = response.data.data.category
          this.header = response.data.data.category_name
          this.$store.commit('setHeaderColor', response.data.data.header_color ? response.data.data.header_color : 'white')
          this.createPlayerIndicator(this.data)
          if (this.yIndexHome) {
            this.scrollIntoView()
          }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    this.setMetaInfo(null, this.$route.name)
    this.getSlider()

    if (this.brand !== '14') {
      this.$router.push({name: 'Home'})
    }

    this.$store.commit('setIsHome', true)
    this.$store.commit('setIsSearch', false)
    this.$store.commit('setIsLoginPage', false)
    this.$store.commit('setIsMemberPage', false)
    this.$store.commit('setActivePage', 'HOME')
  }, 
  destroyed () {
    this.$store.commit('setHeaderColor', 'black')
    this.click = 0
  }
}
</script>

<style scoped>
 .slider-content {
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .text-center-m {
    /* color: white; */
    text-align: center;
    position: relative;
    margin: auto;
    margin-bottom: -400px;
    z-index: 1;
  }

  .text-center {
    /* color: white; */
    position: relative;
    margin: auto;
    margin-bottom: -450px;
    z-index: 1;
  }

  .title {
    /* font-family: 'Helvetica' !important; */
    font-size: 36px !important;
    font-weight: bolder;
  }

  .subtitle {
    margin-top: 40px;
    font-size: 10px !important;
    max-width: 300px;
  }

  .center {
    text-align: center;
    margin: 0;
  }
</style>
